import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import familieLeker from "../../../static/img/bilder/familie-leker.jpg";
import flytteesker from "../../../static/img/bilder/flytteesker.jpg";
import snekker from "../../../static/img/bilder/snekker.jpg";
import tomatbonde from "../../../static/img/bilder/tomatbonde.jpg";
export const _frontmatter = {
  "title": "Bilder",
  "order": 8,
  "template": "default",
  "introtext": "Bildene våre handler om folk og bedrifter, deres hverdag og det de oppnår. Vi ser ekte mennesker i hverdagslige situasjoner"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Bildestil`}</h2>
    <p>{`Bildene viser situasjoner som er rolige og trygge, inkluderende og som har en avslappet stemning. Aller viktigst er det å ha lokale bilder som gjør at kunden kjenner seg igjen og styrker at vi er lokale banker.`}</p>
    <p>{`Vi er tett på folk og bedrifter i deres hverdagslige eller lokale miljø. Utsnittet kan derfor variere fra helfigur til «halv figur». Bruk gjerne utsnitt som gjør at vi titter inn på situasjonen som «en flue på veggen». Menneskene i bildet holder på med sitt og har kontakt med de andre som er i bildet, de titter ikke inn i kamera.`}</p>
    <img src={familieLeker} alt="SpareBank 1 bildeeksempel familie" className="sb1ds-layout__spacing-bottom--md" />
    <img src={flytteesker} alt="SpareBank 1 bildeeksempel flytte" className="sb1ds-layout__spacing-bottom--md" />
    <img src={snekker} alt="SpareBank 1 bildeeksempel snekker" className="sb1ds-layout__spacing-bottom--md" />
    <img src={tomatbonde} alt="SpareBank 1 bildeeksempel tomatbonde" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Se alle bildene`}</h2>
    <p>{`Bildebiblioteket ligger i Merkevare- og profilsenteret. Der kan du få full oversikt og laste ned bildene du trenger.`}</p>
    <p>{`Du finner Merkevare- og profilsenteret ved å gå inn på innsikt og klikke på menypunktet «Merkevare- og profilsenter».`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      